<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Client Service Settings</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Client Name : {{ this.localClientName ? this.localClientName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="clientServices" :lazy="true" :paginator="false" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage" class="p-datatable-users"
                                data-key="bd1" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <!-- <Toolbar>
                                    <template v-slot:left>
                                        <h5 class="p-m-0">Master Client Services Of Vision Creative Group</h5>
                                    </template>
                                </Toolbar> -->
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column header="Sr." headerStyle="width: 4%">
                                    <template #body="{ index }">
                                        <div>{{ genrateSrNo(index) }}</div>
                                    </template>
                                </Column>
                                <Column header="Service Name" field="components_name" headerStyle="width: 75%"></Column>
                                <Column header="Master Service Status" headerStyle="width: 20%">
                                    <template #body="{ data }">
                                        <InputSwitch v-model="data.service_status_by_admin"
                                            @change="servicestatuschanges(data)" />
                                    </template>
                                </Column>
                            </DataTable>
                            <Toast />
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../../service/ApiService';
// import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import router from '@/router';
// import { required, helpers } from '@vuelidate/validators';

export default {
    data() {
        return {
            clientServices: [],
            // v$: useValidate(),
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            switchuncheck: false,
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,
            localClientName:'',
        };
    },
    // validations() {
    //     return {
    //         // remark: { required: helpers.withMessage('Please enter remark', required) },
    //     };
    // },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.localClientName = localStorage.localClientName;
        this.getClientServiceSettings({ client_id: this.routeParam });
    },
    methods: {
        servicestatuschanges(data) {
            let fields = {};
            fields["client_id"] = this.routeParam;
            if (data.service_status_by_admin == 0 || data.service_status_by_admin == false) {
                fields["service_status_by_admin"] = 0;
            }
            if (data.service_status_by_admin == 1 || data.service_status_by_admin == true) {
                fields["service_status_by_admin"] = 1;
            }
            fields["component_id"] = data.component_id;
            fields["components_name"] = data.components_name;
            fields["serviceSettingId"] = data.serviceSettingId;
            this.ApiService.addClientServiceSettings(fields).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.getClientServiceSettings({ client_id: this.routeParam });
                }
                else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getClientServiceSettings({ page_no: event.page });
        },
        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.routeParam },

            });
        },
        goToPrevious() {
            router.push({
                name: "client-document",
                params: { clientSubId: this.routeParam },

            });
        },
        goToNext() {
            router.push({
                name: "add-debit",
                params: { clientSubId: this.routeParam },
            });
        },
        async getClientServiceSettings(payload) {
            try {
                const result = await this.ApiService.getClientServiceSettings(payload);
                this.clientServices = [];
                let clientServices = result.data;
                let arrConverted = clientServices;
                for (let i = 0; i < arrConverted.length; i++) {
                    if (clientServices[i]['service_status_by_admin'] != null) {
                        this.clientServices.push({
                            'component_id': clientServices[i]['component_id'],
                            'components_name': clientServices[i]['components_name'],
                            'serviceSettingId': clientServices[i]['serviceSettingId'],
                            'client_id': clientServices[i]['client_id'],
                            'service_status_by_admin': clientServices[i]['service_status_by_admin'] == 1 ? true : false,
                        });
                    }
                }
                if (!result.success) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                }
            } catch (error) {
                return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
            }
        },
    }

};
</script>

<style scoped></style>
